import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { createCurrencyFormatter } from '../createCurrencyFormatter';

interface CreateRuleDescriptionParams {
  rule: LoyaltyEarningRule;
  flowAPI: ControllerFlowAPI;
  loyaltyProgram: LoyaltyProgram;
  configIndex: number;
}

export const createRuleDescription = ({ rule, flowAPI, loyaltyProgram, configIndex }: CreateRuleDescriptionParams) => {
  const formatCurrency = createCurrencyFormatter(flowAPI);
  const { t } = flowAPI.translations;
  if (rule.triggerActivityType) {
    switch (rule.triggerActivityType) {
      case 'wix-restaurants/orderSubmitted': {
        const { moneyAmount, points } = rule.conversionRate?.configs![configIndex]!;
        const key = loyaltyProgram.pointDefinition?.customName
          ? 'app.rule-description.conversion-rate-custom'
          : 'app.rule-description.conversion-rate';
        return t(key, {
          pointsName: loyaltyProgram.pointDefinition?.customName,
          pointsAmount: points,
          moneyAmount: formatCurrency(moneyAmount ?? 0),
        });
      }

      case 'auth/register': {
        const { points } = rule.fixedAmount?.configs![configIndex]!;
        const key = loyaltyProgram.pointDefinition?.customName
          ? 'app.rule-description.fixed-amount-custom'
          : 'app.rule-description.fixed-amount';

        return t(key, {
          pointsName: loyaltyProgram.pointDefinition?.customName,
          pointsAmount: points,
        });
      }
    }
  }

  if (rule.conversionRate) {
    const { moneyAmount, points } = rule.conversionRate?.configs![configIndex]!;
    const key = loyaltyProgram.pointDefinition?.customName
      ? 'app.rule-description.conversion-rate-custom'
      : 'app.rule-description.conversion-rate';

    return t(key, {
      pointsName: loyaltyProgram.pointDefinition?.customName,
      pointsAmount: points,
      moneyAmount: formatCurrency(moneyAmount ?? 0),
    });
  }

  if (rule.fixedAmount) {
    const { points } = rule.fixedAmount?.configs![configIndex]!;
    const key = loyaltyProgram.pointDefinition?.customName
      ? 'app.rule-description.fixed-amount-custom'
      : 'app.rule-description.fixed-amount';

    return t(key, {
      pointsName: loyaltyProgram.pointDefinition?.customName,
      pointsAmount: points,
    });
  }

  return '';
};
